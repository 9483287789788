header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: linear-gradient(90deg , #5445d6, #7967ff);/* gradient background */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    z-index: 1000;/*set header stay above other components */
}

.left-part {
    display: flex;
    align-items: center;
}

.logo {
    font-family: 'STHupo', sans-serif; /*choose the cutest font for logo design*/
    color: #ffffff;
    font-size: 25px;
    margin: 10px;  
    font-weight: bold;
}


.icon {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 20px;
    margin: 10px; 
    cursor: pointer;
}


.icon:hover {
    transform: scale(1.1); /*icon become bigger when hover */
}


.right-part {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}




@media (max-width: 1200px) {
    .logo {
        font-size: 24px; 
    }

    .header-icon-button {
        font-size: 19px; 
    }

}


@media (max-width: 768px) {
    .logo {
        font-size: 22px; 
    }

    .icon {
        font-size: 18px; 
    }

}

@media (max-width: 480px) {
    .logo {
        font-size: 22px; 
        margin-right: 8px;
        margin-left: 0px;
    }

    .icon {
        font-size: 18px; 
        margin: 5px;
    }
}
