.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f6fa;
    padding: 20px;
    width: 100%;
}

.login-page-title {
    color: #2e2e2e;
    font-size: 28px;
    padding: 20px;
    font-weight: bold;
    display: block; /* display the title as block*/
    text-align: center;
}

.login-page-form {
    background-color: #fff;
    padding: 50px; 
    border-radius: 20px;
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
}

.login-page-label {
    color: #2e2e2e;
    font-size: 14px;
    font-weight: bold;
}

.login-page-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    color: #333;
    width: 100%;
    margin-bottom: 10px;
}




.login-page-button {
    padding: 12px 20px;
    background-color: #7967ff;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
}

.login-page-button:hover {
    background-color: #5445d6;
}

.login-page-link {
    color: #7967ff;
    margin-top: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 14px;
    display: block; 
    text-align: center;
}

.login-page-link:hover {
    color: #5445d6;
}


.register-link {
    color: #7967ff;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
    display: block; 
    text-align: center; 
}


.register-link:hover {
    color: #5445d6;
}


.reset-password h3 {
    color: #333;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
}




@media (max-width: 1200px) {
    .login-page {
        padding-top: 0px;
       margin-top: 0px; 
    }


    .login-page-form {
        padding: 40px; 
    }

    .login-page-title {
        font-size: 24px; 
        margin-bottom: 0px;
    }

    .login-page h3 {
        font-size: 17px;
        margin-bottom: 0px;
    }
    
    .login-page-button {
        padding: 10px; 
        font-size: 14px; 
    }
}


@media (max-width: 768px) {
    .login-page {
        padding-top: 0px;
       margin-top: 0px; 
    }

    .login-page-form {
        padding: 40px; 
    }

    .login-page-title {
        font-size: 24px; 
        margin-bottom: 0px;
    }

    .login-page h3 {
        font-size: 17px; 
        margin-bottom: 0px;
    }
    
    .login-page-button {
        padding: 10px; 
        font-size: 14px; 
    }
}

@media (max-width: 480px) {
    .login-page {
        padding-top: 0px; 
       margin-top: 0px; 
    }

    .login-page-form {
        padding: 30px;
    }

    .login-page-title {
        font-size: 20px; 
        margin-bottom: 0px;
    }

    .login-page h3 {
        font-size: 15px; 
        margin-bottom: 0px;
    }
    
    .login-page-button {
        padding: 8px; 
        font-size: 12px; 
    }
}
