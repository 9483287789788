/* general styling for the body and html elements */
body {
  background-color: #ffffff;
  color: #333;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

html,
body {
  height: 100%;
  overflow-y: auto;
}



.shared-toilet-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 0 auto;
  flex-direction: row;
  width: 90vw;
  max-width: 1400px;
  background-color: #f5f6fa;
  height: auto;
  box-shadow: none /*no box shadow here*/
}


.shared-toilet-page-submit {
  margin: 0;
  padding: 40px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  max-width: 500px;/*limit the width of submit form*/
  min-width: 300px;
  height: 81.8vh;

}

.shared-toilet-page-update {
  margin: 0;
  padding: 40px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  max-width: 500px;
  min-width: 300px;
  height: 81.8vh;
  min-height: 300px; 
}


.shared-toilet-page-empty {
  display: flex;
  justify-content: center; 
  align-items: center; 
flex-grow: 1; /* ensure this div take up remaining space to center the content inside */
}


.shared-toilet-page-empty p {
  text-align: center; 
  font-size: 15px; 
  color: #666; 
}


.shared-toilet-page-update ul {
  overflow-y: auto;
  padding: 10px;
  margin: 0;
}


.shared-toilet-page-title {
  font-size: 24px;
  font-weight: bold;
  color: #6c63ff;
  margin-bottom: 20px;
  text-align: center;
}

.shared-toilet-page-subtitle {
  font-size: 15px;
  font-weight: bold;
  color: #2e2e2e;
  padding: 10px;
}



.shared-toilet-page-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.shared-toilet-page-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;
  font-size: 15px;
  color: #333;
}

.shared-toilet-page-availability {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.shared-toilet-page-checkbox {
  margin: 15px;
}

.shared-toilet-page-button {
  padding: 12px 20px;
  background-color: #7967ff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  margin: 0;
}

.shared-toilet-page-button :hover {
  background-color: #5445d6;
}

ul {
  list-style: none;
  padding: 0;
  margin-right: 30px;
}

.shared-toilet-page-item {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 15px;
  word-wrap: break-word;
}


.shared-toilet-page-update-bottons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}


.shared-toilet-page-item button {
  padding: 8px 20px;
  background-color: #7967ff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
  min-width: 45%;
}

.shared-toilet-page-item button:hover {
  background-color: #5445d6;
}



@media (max-width: 1200px) {
  .shared-toilet-page-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0;
  }

  .shared-toilet-page-title {
    margin: 0;
  }


  .shared-toilet-page-subtitle {
    font-size: 15px;
    font-weight: bold;
    color: #2e2e2e;
    padding: 10px;
  }
  
  
  .shared-toilet-page-form {
    gap: 10px;
    margin: 5px;
  }
  
  .shared-toilet-page-input {
    padding: 8px;
    font-size: 15px;
  }
  
  .shared-toilet-page-availability {
    font-size: 15px;
    margin: 0;
  }
  
  .shared-toilet-page-checkbox {
    margin: 10px;
  }
  
  .shared-toilet-page-button {
    padding: 10px 20px;
    font-size: 15px;
    margin: 0;
  }


  .shared-toilet-page-submit {
    height: 50vh;
    max-height: 600px;
  }

  .shared-toilet-page-submit,
  .shared-toilet-page-update {
    width: 90%;
    padding: 30px;
  }

}


@media (max-width: 768px) {
  .shared-toilet-page-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 700px;
    padding: 15px;
    width: 100%;
    gap: 15px;
  }

  .shared-toilet-page-submit {
    height: 50vh;
    max-height: 600px;
  }

  .shared-toilet-page-submit,
  .shared-toilet-page-update {
    width: 100%;
    max-width: none;
    height: auto;
  }


  .shared-toilet-page-title {
    font-size: 22px;
    margin-bottom: 0px;
  }

  .shared-toilet-page-subtitle {
    font-size: 14px;
    padding: 10px 10px 0px 10px;
  }


  .shared-toilet-page-form {
    gap: 10px;
    margin-bottom: 5px;
  }

  .shared-toilet-page-input {
    padding: 8px;
    font-size: 14px;
    width: 100%;
  }

  .shared-toilet-page-availability {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .shared-toilet-page-button {
    padding: 8px 16px;
    font-size: 15px;
  }

  .shared-toilet-page-item {
    padding: 20px;
    margin-bottom: 10px;
    font-size: 13px;
  }

  .shared-toilet-page-item button {
    padding: 8px;
    font-size: 12px;
    margin-left: 8px;
  }
}

@media (max-width: 480px) {
  .shared-toilet-page-container {
    max-width: 400px;
    padding: 15px;
    width: 100%;
    gap: 10px;
  }

  .shared-toilet-page-submit,
  .shared-toilet-page-update {
    width: 100%;
    padding: 22px;
  }

  .shared-toilet-page-title {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .shared-toilet-page-subtitle {
    font-size: 15px;
    padding: 8px;
  }

  .shared-toilet-page-form {
    gap: 10px;
    margin-bottom: 10px;
  }

  .shared-toilet-page-input {
    padding: 8px;
    font-size: 12px;
    width: 100%;

  }

  .shared-toilet-page-availability {
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .shared-toilet-page-button {
    padding: 8px;
    font-size: 14px;
  }

  .shared-toilet-page-item {
    padding: 20px;
    margin-bottom: 15px;
    font-size: 12px;
  }

  .shared-toilet-page-item button {
    padding: 8px;
    font-size: 12px;
    margin-left: 10px;
  }
}