.container {
  margin: 0;
  background-color: #f5f6fa;
  border-radius: 10px;
  height: 100vh;
  min-height: 90vh;/*ensure there is enough height for viewing*/
}


.list {
  border-radius: 10px;
  padding: 0;
  width: 100%;
}


.item {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 15px;
  word-wrap: break-word; /* ensure long words can fit the div*/
}



.info {
  flex: 1;
}
  


.name-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.price {
  font-size: 18px;
  font-weight: bold;
  color: #7967ff;
}

.detail-info {
  font-size: 13px;
  color: #6d6e6f;
  margin-bottom: 10px;
}

.rating-star {
  margin-bottom: 20px;
  color: #f1c40f;
}


.rating-number {
  margin-left: 10px;
  font-size: 14px;
  color: #6d6e6f;
}

.button {
  display: flex;/* Flexbox for chidren buttons to make them on the same row */
  gap: 10px;/*add gap for them*/
  justify-content: space-between;/*ensure button are on the different sides*/
}

.button button {
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #7967ff;
  width: 100%;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 14px;
}

.button button:hover {
  background-color: #5445d6;
}


/*for device with larger screen*/
@media (max-width: 1200px) {
  .name {
    font-size: 17px;
  }

  .price {
    font-size: 16px;
  }

  .detail-info {
    font-size: 14px;
  }

  .rating-number {
    font-size: 13px;
  }

  .button button {
    font-size: 14px;
  }
}

/*for tablet or other device that screen width less that 768px*/
@media (max-width: 768px) {
  .name {
    font-size: 15px;
  }

  .price {
    font-size: 15px;
  }

  .detail-info {
    font-size: 12px;
  }

  .rating-number {
    font-size: 12px;
  }

  .button button {
    font-size: 13px;
  }
}


/*for mobile phone*/
@media (max-width: 480px) {
   .container {
    padding: 10px;
  }
 
  .item {
    padding: 20px;
    margin-bottom: 10px;
  }   

  .name {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .price {
    font-size: 14px;
  }

  .detail-info {
    font-size: 12px;
  }

  .rating-number {
    font-size: 12px;
  }

  .button button {
    font-size: 12px;
  }
}