.rate-and-review-container {
  padding: 20px;
  background-color: #f5f6fa;
  border-radius: 15px;
  width: 97%;
  margin-top: 10px;
}

.rating-part {
  text-align: center;
  margin-bottom: 40px;
}


.rating-part h1 {
  font-size: 28px;
  color: #333;
  padding: 5px;
  font-weight: bold;
}

.overall-rating-review {
  display: flex;
  justify-content: center;
  gap: 20px;
}


.rating-number {
  margin-left: 8px;
  font-size: 12px;
  color: #2d3436;
}


.rating-part p {
  font-size: 14px;
  color: #747474;
  margin-top: 0px;
  margin-bottom: 10px;
}

.write-review-button {
  padding: 12px 24px;
  background-color: #7967ff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 200px;

}

.write-review-button:hover {
  background-color: #5445d6;
}


.review-part {
  margin-top: 10px;
  overflow-y: auto;
  height: 65.5vh;
}

.no-review {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 50vh;
}


.review-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 20px;
  width: 100%;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.user-and-date {
  display: flex;
  align-items: center;
}


.user-and-date h6 {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.user-and-date h7 {
  font-size: 12px;
  color: #696a6a;
}


.review-box p {
  font-size: 18px;
  color: #2d3436;
  line-height: 1.6;
  margin: 10px 0;
  word-wrap: break-word;
}

.review-stars {
  display: flex;
  align-items: center;
}

.review-stars-1 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}



.rating-and-delete {
  display: flex;
  align-items: center;
}

.delete-icon {
  margin-left: 15px;
  color: #ff503c;
  cursor: pointer;
  font-size: 15px;
}

.delete-icon:hover {
  color: #cf3827;
}


@media (max-width: 1200px) {
  .review-part {
    height: 67vh;
  }

  .review-box {
    padding: 20px;
  }

  .rating-part {
    margin-bottom: 30px;

  }

}

@media (max-width: 768px) {
  .rate-and-review-container {
    width: 100%;
    padding: 5px;
  }

  .review-part {
    height: 73vh;
  }

  .review-box {
    padding: 18px;
  }

  .rating-part h1 {
    font-size: 22px;
  }

  .review-item {
    padding: 20px;
    margin-bottom: 15px;
  }

  .review-box p {
    font-size: 16px;
  }

  .write-review-button {
    padding: 10px 14px;
    font-size: 16px;
  }

  .rating-part {
    margin-bottom: 25px;
  }
}

@media (max-width: 480px) {
  .rate-and-review-container {
    padding: 5px;
    width: 100%;
  }

  .review-box {
    padding: 15px;
    margin-bottom: 10px;
  }

  .review-part {
    height: 76vh;
  }

  .write-review-button {
    padding: 8px 12px;
    font-size: 14px;
  }

  .rating-part {
    margin-bottom: 20px;
  }
}