.write-review-container {
  width: 50%;
  max-width: 800px; 
  margin: 50px auto; 
  padding: 30px;
  background-color: #fff; 
  border-radius: 10px; 
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15); 
}

.write-review-title {
  text-align: center;
  color: #6c63ff;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.write-review-container label {
  margin-bottom: 10px;
  margin-left: 5px;
  color: #555;
  font-size: 16px;
  align-items: center;
  display: flex;
}

.write-review-input, 
.write-review-textarea {
  width: 100%; 
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}


.write-review-container textarea {
  min-height: 100px; /*ensure enough space for user to input comment*/
  resize: vertical; /*allow them to adjust the hight of input box*/
}


.write-review-button {
  display: block;
  width: 100%;
  padding: 10px 0;
  background-color: #7967ff;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
}

.write-review-button:hover {
  background-color: #5445d6;
}



@media (max-width: 1200px) {
  .write-review-container {
    width: 60%;
    padding: 25px;
  }

  .write-review-title {
    font-size: 23px;
  }

  .write-review-container label {
     font-size: 15px;
   }

  .write-review-input, 
  .write-review-textarea {
    font-size: 14px;
  }

  .write-review-button {
    font-size: 14px;
    padding: 10;
  }
}



@media (max-width: 768px) {
  .write-review-container {
    width: 85%;
    padding: 20px;
  }

  .write-review-title {
    font-size: 22px;
  }

  .write-review-container label {
    font-size: 14px;
  }

  .write-review-input, 
  .write-review-textarea {
    font-size: 14px;
  }

  .write-review-button {
    font-size: 14px;
    padding: 8px 0;
  }
}


@media (max-width: 480px) {
  .write-review-container {
    width: 95%;
    padding: 15px;
  }

  .write-review-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .write-review-container label {
    font-size: 12px;
  }

  .write-review-input, 
  .write-review-textarea {
    font-size: 14px;
    padding: 8px;
  }

  .write-review-button {
    font-size: 14px;
    padding: 8px;
  }
}